import React from "react"
import Seo from "../components/Seo"

import { Link } from "gatsby"

const ThankYouPage = () => {
  return (
    <>
      <Seo title="Gracias por tu mensaje" />
      <main style={{minHeight: "100vh"}}>
				<section>
					<div className="section-inner-wrapper" style={{height: "500px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
						<h1 style={{margin: "1rem"}}>Gracias por tu mensaje span <span role="img" aria-label="wink eye" aria-labelledby="wink eye">😉</span></h1>
						<Link to="/" className="btn">
              <span role="img" aria-label="back to home" aria-labelledby="back to home">🏡</span>
              &nbsp;Regresar al inicio
            </Link>
					</div>
				</section>
      </main>
    </>
  )
}

export default ThankYouPage